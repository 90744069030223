import Script from 'next/script'
import ym from 'react-yandex-metrika'

export const TawkChatInitializer = () => {
  return (
    <Script
      strategy="lazyOnload"
      src="https://embed.tawk.to/674065932480f5b4f5a26a94/1id9opi72"
      onLoad={() => {
        window.Tawk_API = window.Tawk_API || {}
        window.Tawk_API.onChatStarted = () => {
          ym('reachGoal', 'tawk_start_chat')
        }
        window.Tawk_API.onChatEnded = () => {
          ym('reachGoal', 'tawk_end_chat')
        }
        window.Tawk_API.onChatMaximized = () => {
          ym('reachGoal', 'tawk_open_chat')
        }
      }}
    />
  )
}
